<template>
  <div class="set-style" :style="{
    backgroundImage: `url(${info.selectedimage})`,
  }">
    <div class="box1 center-center">
      <van-icon name="arrow-left" size="20" @click="goback" />
    </div>
    <div class="addbox1 center-center">
      <img v-if="info.is_collect == 1" src="@/assets/img/img20.png" @click="collectNft" class="addi1" />
      <img v-else src="@/assets/img/img21.png" @click="collectNft" class="addi1" />
    </div>
    <div class="box2 end-center">
      <div class="addbg"></div>
      <div class="left">
        <img :src="info.collectionimage" class="i1" />
      </div>
      <div class="right evenly-center">
        <div class="items">
          <div class="tt1">{{ $t('text.t293') }}</div>
          <div class="tt2">{{ info.collections }}</div>
        </div>
        <div class="items">
          <div class="tt1">{{ $t('text.t294') }}</div>
          <div class="tt2">{{ info.holders }}</div>
        </div>
        <div class="items">
          <div class="tt1">{{ $t('text.t295') }}</div>
          <div class="center-center">
            <img src="@/assets/img/img43.png" class="ii1" />
            <div class="tt2">{{ Number(info.min_price).toFixed(4) }}</div>
          </div>
        </div>
        <div class="items">
          <div class="tt1">{{ $t('text.t296') }}</div>
          <div class="center-center">
            <img src="@/assets/img/img43.png" class="ii1" />
            <div class="tt2">{{ Number(info.total_price).toFixed(4) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="row1 van-ellipsis">{{ info.collectionname }}</div>
      <div class="row2 start-center">
        <img :src="chain_list.find(ele => ele.chain_type == info.chain)?.chain_logo" class="r2-i1" />
        <div class="r2-t1">
          {{ info.contain?.slice(0, 7) }}...{{
            info.contain?.slice(info.contain?.length - 5)
          }}
        </div>
        <img src="@/assets/img/img75.png" class="r2-i2" @click="copy(info.contain)" />
      </div>
      <div class="row3 start-center" v-if="info.classificationInfo && info.classificationInfo.length">
        <div class="row3item center-center" v-for="item in info.classificationInfo" :key="item.id">
          <span class="itemt1">#</span>
          <span>{{ language == 1 ? item.title : item.etitle }}</span>
        </div>
      </div>
      <div class="row4">
        {{ info.collectionintro }}
      </div>
      <div class="row5 start-center">
        <img src="@/assets/img/img68.png" v-if="info.link" alt="" class="row5-i1" @click="goOutLink(info.link)" />
        <img src="@/assets/img/img3.png" v-if="info.twitter" alt="" class="row5-i1" @click="goOutLink(info.twitter)" />
        <img src="@/assets/img/img67.png" v-if="info.telegram" alt="" class="row5-i1" @click="goOutLink(info.telegram)" />
        <img src="@/assets/img/img66.png" v-if="info.discard" alt="" class="row5-i1" @click="goOutLink(info.discard)" />
      </div>
    </div>
    <div class="box4 start-center">
      <div class="item" :class="active == 1 ? 'ac' : 'no'" @click="changes(1)">
        {{ $t('text.t293') }}
      </div>
      <div class="item" :class="active == 2 ? 'ac' : 'no'" @click="changes(2)">
        {{ $t('text.t412') }}
      </div>
    </div>
    <div class="box5" v-show="active == 1">
      <div class="row1 between-center">
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="chose center-center">
            <div class="t1">{{ names }}</div>
            <van-icon name="arrow-down" size="16" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in common_list" :key="item.type"
              :command="item.type">{{ item.text }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="right end-center">
          <!-- <div class="rbox1 center-center">
            <img src="@/assets/img/img77.png" class="ri1" />
          </div> -->
          <!-- <div class="rbox1 center-center"><img src="@/assets/img/img76.png" class="ri1" /></div> -->
        </div>
      </div>
      <van-list v-model="loading" :finished="finished" :finished-text="$t('text.t39')" :offset="10" @load="getlist">
        <div class="nftlist start-center">
          <div class="items-out" v-for="item in list" :key="item.id">
            <div class="items"
              @click="item.onType == '1' ? goAhead(`/newdetail?orderno=${item.orderno}&chainType=${item.chain}`) : goAhead(`/newdetail?id=${item.id}&isup=1&chainType=${item.chain}`)">
              <img v-if="item.status == 1" :src="item.image" alt="" class="i1" />
              <img v-else-if="item.status == 0" :src="language == '1' ? $concatImg('img60') : $concatImg('img61')" alt=""
                class="i1" />
              <img v-else-if="item.status == 2" :src="language == '1' ? $concatImg('img62') : $concatImg('img63')" alt=""
                class="i1" />
              <div class="b1 between-center">
                <div class="t1 ellipsis">{{ item.title }}</div>
                <!-- <img src="@/assets/img/s.png" class="b1i1" /> -->
              </div>
              <div class="b2">#{{ item.tokenid }}</div>
              <div class="b3 start-center">
                <img src="@/assets/img/img43.png" class="b3i1" />
                <div class="b3t1" v-if="item.onType == '1'">{{ item.price }}</div>
                <div class="b3t1" v-else>---</div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="box6" v-show="active == 2">
      <div class="row1 start-center">
        <div class="pub" :class="timeType == item.type ? 'act' : 'nor'" v-for="item in timesList" :key="item.type"
          @click="changeTimeType(item.type)">
          {{ item.text }}
        </div>
      </div>
      <div class="row2">
        <div class="row2_item between-center">
          <div class="ri_line start-center">
            <div class="r2_text1">{{ $t('text.t549',{timeType:timeType}) }}</div>
            <div class="r2_text2 start-center">
              <img :src="info.chain == 0
                ? $concatImg('img81')
                : info.chain == 1
                ? $concatImg('img79')
                : info.chain == 2
                ? $concatImg('img80')
                : info.chain == 3
                ? $concatImg('img82')
                : ''" alt="" class="r2_img1">
              <div class="r2_text3">{{ lineInfo.average }}</div>
            </div>
          </div>
          <div class="ri_line start-center">
            <div class="r2_text1">{{ $t('text.t550',{timeType:timeType}) }}</div>
            <div class="r2_text2 start-center">
              <div class="r2_text3">{{ lineInfo.floor }}%</div>
            </div>
          </div>
        </div>
        <div class="between-center">
          <div class="ri_line start-center">
            <div class="r2_text1">{{ $t('text.t551',{timeType:timeType}) }}</div>
            <div class="r2_text2 start-center">
              <img :src="info.chain == 0
                ? $concatImg('img81')
                : info.chain == 1
                ? $concatImg('img79')
                : info.chain == 2
                ? $concatImg('img80')
                : info.chain == 3
                ? $concatImg('img82')
                : ''" alt="" class="r2_img1">
              <div class="r2_text3">{{ lineInfo.total }}</div>
            </div>
          </div>
          <div class="ri_line start-center">
            <div class="r2_text1">{{ $t('text.t552',{timeType:timeType}) }}</div>
            <div class="r2_text2 start-center">
              <div class="r2_text3">{{ lineInfo.number }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="isshowLine" class="row3" ref="chart"></div>
      <div v-show="!isshowLine" class="row4 column-center">
        <img src="@/assets/svg/zhexian.svg" class="imgg1" alt="">
        <div class="texxt1">{{ $t('text.t427') }}</div>
      </div>
      <div class="add_cj">{{ $t('text.t400') }}</div>
      <div class="list">
        <van-list
          v-model="loading1"
          :finished="finished1"
          :finished-text="$t('text.t39')"
          :offset="10"
          @load="gethislist"
        >
          <div class="item" v-for="item in hislist" :key="item.id">
            <div class="row1 between-center">
              <img :src="info.collectionimage" class="i1" />
              <div class="b1">
                <div class="t1">#{{ item.tokenid }}</div>
                <div class="t2">{{ $parseTime(item.createtime) }}</div>
              </div>
              <div class="b2 end-center">
                <div class="left">
                  <div class="t1">{{ item.total }} USDT</div>
                  <div class="t2">{{ $t("text.t401") }}</div>
                </div>
                <van-icon
                  v-if="item.is_open"
                  name="arrow-up"
                  size="12"
                  color="#909090"
                  @click="changeOpen(item)"
                />
                <van-icon
                  v-else
                  name="arrow-down"
                  size="12"
                  color="#909090"
                  @click="changeOpen(item)"
                />
              </div>
            </div>
            <div v-show="item.is_open">
              <div class="row2 between-center">
                <div class="row2item center-center">
                  <div class="r2t1">{{ $t("text.t402") }}</div>
                  <div class="r2t2">
                    {{ item.seller?.slice(0, 6) }}...{{
                      item.seller?.slice(item.seller.length - 5)
                    }}
                  </div>
                  <img
                    src="@/assets/img/img75.png"
                    class="r2i1"
                    @click="copy(item.seller)"
                  />
                </div>
                <div class="row2item center-center">
                  <div class="r2t1">{{ $t("text.t403") }}</div>
                  <div class="r2t2">
                    {{ item.address?.slice(0, 6) }}...{{
                      item.address?.slice(item.address.length - 5)
                    }}
                  </div>
                  <img
                    src="@/assets/img/img75.png"
                    class="r2i1"
                    @click="copy(item.address)"
                  />
                </div>
              </div>
              <div class="row2 add_row2 between-center">
                <div class="row2item center-center">
                  <div class="r2t1">{{ $t("text.t574") }}</div>
                  <div class="r2t2">
                    {{ item.hash?.slice(0, 6) }}...{{
                      item.hash?.slice(item.hash.length - 5)
                    }}
                  </div>
                  <img
                    src="@/assets/img/img75.png"
                    class="r2i1"
                    @click="copy(item.hash)"
                  />
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      active: 1,
      value: "",
      id: "",
      info: {},
      isloading: false,
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
      chainType: "",
      common_list: [
        { type: 1, text: this.$t('text.t420') },
        { type: 2, text: this.$t('text.t421') },
        { type: 3, text: this.$t('text.t422') },
        { type: 4, text: this.$t('text.t423') },
        { type: 5, text: this.$t('text.t424') },
      ],
      type: '',
      names: '',
      timeType: 7,
      hislist: [],
      loading1: false,
      finished1: false,
      page1: 1,
      totalArr:[],
      averageArr:[],
      flagArr:[],
      lineInfo:{
        number:0,
        total:0,
        average:0,
        floor:0
      },
      isshowLine:false,
      isCanChange:false,
      isChartOk:false
    };
  },
  computed: {
    ...mapState(["language", "chain_list", "chainId"]),
    timesList() {
      return [
        { type: 7, text: this.$t('text.t553',{day:7}) },
        { type: 14, text: this.$t('text.t553',{day:14}) },
        { type: 30, text: this.$t('text.t553',{day:30}) },
        { type: 60, text: this.$t('text.t553',{day:60}) },
        { type: 90, text: this.$t('text.t553',{day:90}) }
      ]
    }
  },
  created() {
    this.type = this.common_list[0].type
    this.names = this.common_list[0].text

    this.id = this.$route.query.id;
    this.chainType = this.$route.query.chainType;
    this.getdetail();
  },
  watch:{
    isChartOk(val,old){
      if(this.isshowLine){
        this.$nextTick(()=>{
          this.getEchartData()
        })
      }
    }
  },
  methods: {
    getLineList(){
      this.$http.get('/core/linelist',{
        contract:this.info.contain,
        chainType:this.info.chain,
        limit:this.timeType
      }).then(res=>{
        this.totalArr = res.data.totalArr.reverse()
        this.averageArr = res.data.averageArr.reverse()
        this.flagArr = res.data.flagArr.reverse()
        this.lineInfo.number = res.data.number
        this.lineInfo.total = res.data.total
        this.lineInfo.average = res.data.average
        this.lineInfo.floor = res.data.floor
        this.isshowLine = this.totalArr.length ? true : false
        if(this.isChartOk && this.isshowLine){
          this.$nextTick(()=>{
            this.getEchartData()
          })
        }
      })
    },
    changeTimeType(e) {
      this.timeType = e
      this.getLineList()
    },
    handleCommand(command) {
      this.type = command
      this.names = this.common_list.find(ele => ele.type == command).text
      this.onSearch()
    },
    changes(e) {
      if(!this.isCanChange) return
      this.active = e;
      if(e == 2){
        this.isChartOk = true
      }
    },
    goback() {
      this.$router.back();
    },
    onSearch() {
      this.loading = false;
      this.finished = false;
      this.page = 1;
      this.list = [];
    },
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        collection_id: this.id,
        title: this.value,
        language: this.language,
        chainType: this.chainType,
        type: this.type
      };
      this.$http.get("/collections/collectListEnd", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
    collectNft() {
      // const chainType = this.chain_list.find(ele=>ele.chain_id == this.chainId).chain_type
      this.isloading = true;
      this.$http
        .post("/collection/enshrine", {
          id: this.id,
          language: this.language,
          chainType: this.info.chain
        })
        .then((res) => {
          this.$toast(res.msg);
          this.getdetail();
          this.$bus.$emit("upRankinglist", this.id);
        })
        .catch(() => {
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
        });
    },
    getdetail() {
      this.$http
        .get("/collection/collectionDetail", {
          id: this.id,
          chainType: this.chainType,
          language: this.language,
        })
        .then((res) => {
          this.info = res.data;
          this.isCanChange = true
          this.getLineList()
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
        });
    },
    goOutLink(url) {
      window.location.href = url;
    },
    getEchartData() {
      let chartdom = this.$refs.chart
      let myChart = this.$echarts.init(chartdom);
      let option = {
        grid: {
          left: '15%'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            saveAsImage: { show: false }
          }
        },
        legend: {
          data: [this.$t('text.t554'), this.$t('text.t555')]
        },
        xAxis: [
          {
            type: 'category',
            data: this.flagArr,
            axisPointer: {
              type: 'line'
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: this.$t('text.t554'),
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            itemStyle: {
              color: '#D8D8D8',
              borderRadius: [5, 5, 0, 0]
            },
            data: this.totalArr,
            barStyle: {}
          },
          {
            name: this.$t('text.t555'),
            type: 'line',
            smooth: true,
            showSymbol: false,
            yAxisIndex: 0,
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            lineStyle: {
              color: '#004F99'
            },
            data: this.averageArr
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize()
      })
    },
    gethislist() {
      if(!this.info.contain){
        this.loading1 = false;
        return
      }
      let arg = {
        page: this.page1,
        limit: this.limit,
        contract:this.info.contain,
        chainType:this.info.chain,
        language:this.language
      };
      this.$http.get("/core/deallist", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished1 = true;
        this.page1++;
        this.loading1 = false;
        for (let i = 0; i < data.length; i++) {
          data[i].is_open = false
        }
        this.hislist = [...this.hislist, ...data];
      });
    },
    changeOpen(item) {
      for (let i = 0; i < this.hislist.length; i++) {
        if (item.id == this.hislist[i].id) {
          this.hislist[i].is_open = !this.hislist[i].is_open
        }
      }
    },
  },
  beforeDestroy(){
    window.removeEventListener("resize", function () {
      myChart.resize();
    });
  }
};
</script>

<style lang="less">
.set-style {
  background-position: center top;
  background-size: 100% 207px;
  background-repeat: no-repeat;
  position: relative;
  .add_cj{
    font-size: 14px;
    color: #323232;
    font-weight: bold;
    line-height: 20px;
    padding-left: 5px;
  }
  .box1 {
    position: fixed;
    top: 30px;
    left: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
  }

  .addbox1 {
    position: fixed;
    top: 30px;
    right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;

    .addi1 {
      width: 15px;
      height: 15px;
    }
  }

  .box2 {
    padding: 210px 0 16px 26px;
    position: relative;

    .addbg {
      width: 100%;
      height: 207px;
      position: absolute;
      left: 0;
      top: 0;
      background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 1));
    }

    .left {
      position: absolute;
      top: 169px;
      left: 26px;
      width: 80px;
      height: 80px;
      border: 1px solid #323232;
      padding: 2px;
      border-radius: 50%;
      box-sizing: border-box;

      .i1 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .right {
      width: calc(100% - 80px);

      .items {
        text-align: center;

        .tt1 {
          font-size: 12px;
          font-weight: 500;
          color: #909090;
          margin-bottom: 5px;
        }

        .tt2 {
          font-size: 12px;
          font-weight: 500;
          color: #323232;
        }

        .ii1 {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }
  }

  .box3 {
    padding: 0 25px 12px;

    .row1 {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 10px;
    }

    .row2 {
      margin-bottom: 10px;

      .r2-i1 {
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }

      .r2-t1 {
        font-size: 12px;
        font-weight: 500;
        color: #909090;
        margin-right: 10px;
      }

      .r2-i2 {
        width: 12px;
        height: 12px;
      }
    }

    .row3 {
      margin-bottom: 10px;
      flex-wrap: wrap;
      .row3item {
        padding: 7px 10px;
        background: #f8f8f8;
        border-radius: 30px;
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        margin-right: 15px;

        .itemt1 {
          margin-right: 3px;
        }
      }
    }

    .row4 {
      font-size: 12px;
      font-weight: 500;
      color: #909090;
      margin-bottom: 15px;
    }

    .row5 {
      .row5-i1 {
        height: 13px;
        width: auto;
        margin-right: 12px;
      }
    }
  }

  .box4 {
    padding: 0 28px;
    border-bottom: 1px solid #e1e1e1;

    .item {
      padding: 0 0 12px 0;
      margin-right: 20px;
      font-size: 12px;
      font-weight: bold;
    }

    .ac {
      color: #323232;
      border-bottom: 1px solid #323232;
    }

    .no {
      color: #909090;
    }
  }

  .box5 {
    .row1 {
      padding: 9px 20px 16px 30px;

      .chose {
        padding: 10px 17px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        border-radius: 30px;

        .t1 {
          font-size: 12px;
          font-weight: 500;
          color: #323232;
          margin-right: 10px;
        }
      }

      .right {
        .rbox1 {
          width: 30px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #e1e1e1;
          border-radius: 30px;

          .ri1 {
            height: 13px;
            width: auto;
          }
        }

        .mr15 {
          margin-right: 15px;
        }
      }
    }

    .nftlist {
      flex-wrap: wrap;

      .items-out {
        padding: 0 18px 25px;

        .items {
          width: 150px;

          .i1 {
            width: 100%;
            height: 150px;
            border-radius: 3px;
            margin-bottom: 5px;
          }

          .b1 {
            padding: 0 16px 9px 0;

            .t1 {
              flex: 1;
              font-size: 12px;
              font-weight: 500;
              color: #909090;
            }

            .b1i1 {
              width: 10px;
              height: 10px;
              margin-left: 10px;
            }
          }

          .b2 {
            font-size: 16px;
            font-weight: bold;
            color: #323232;
            margin-bottom: 9px;
          }

          .b3 {
            .b3i1 {
              width: 15px;
              height: 15px;
              margin-right: 4px;
            }

            .b3t1 {
              font-size: 12px;
              font-weight: 500;
              color: #323232;
            }
          }
        }
      }
    }
  }

  .box6 {
    padding: 0 15px;

    .row1 {
      padding: 10px 5px;

      .nor {
        color: #909090;
      }

      .act {
        color: #004F99;
      }

      .pub {
        font-size: 12px;
        margin-right: 20px;
      }
    }

    .row2 {
      padding: 0 5px 20px;

      .row2_item {
        margin-bottom: 9px;
      }

      .ri_line {
        max-width: 48%;
      }

      .r2_text1 {
        font-size: 12px;
        color: #909090;
        margin-right: 8px;
      }

      .r2_text2 {
        .r2_img1 {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }

        .r2_text3 {
          font-size: 12px;
          color: #323232;
        }
      }
    }
    .row3{
      width: 100%;
      height: 300Px;
    }
    .row4{
      width: 100%;
      height: 150px;
      .texxt1{
        color: #D8D8D8;
        font-size: 14px;
      }
      .imgg1{
        width: 32px;
        height: 32px;
        margin-bottom: 10px;
      }
    }
    .list {
      .item {
        padding: 15px 0;
        border-bottom: 1px solid #e1e1e1;
        .row1 {
          padding: 0 20px;
          .i1 {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin-right: 8px;
          }
          .b1 {
            flex: 1;
            .t1 {
              font-size: 12px;
              font-weight: bold;
              color: #323232;
              margin-bottom: 10px;
            }
            .t2 {
              font-size: 12px;
              font-weight: 500;
              color: #b2b2b2;
            }
          }
          .b2 {
            .left {
              text-align: right;
              margin-right: 10px;
              .t1 {
                font-size: 12px;
                font-weight: bold;
                color: #323232;
                margin-bottom: 10px;
              }
              .t2 {
                font-size: 12px;
                font-weight: 500;
                color: #b2b2b2;
              }
            }
          }
        }
        .row2 {
          padding: 25px 20px 0;
          .row2item {
            .r2t1 {
              font-size: 12px;
              font-weight: 500;
              color: #909090;
            }
            .r2t2 {
              font-size: 12px;
              font-weight: 500;
              color: #323232;
              margin: 0 5px;
            }
            .r2i1 {
              width: 10px;
              height: 10px;
            }
          }
        }
        .add_row2{
          padding-top: 10px;
        }
      }
    }
  }
}
</style>